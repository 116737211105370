import {useKeycloak} from "@react-keycloak/web";
import {Navigate} from "react-router-dom";
import "./AuthRedirect.css";

const AuthRedirect = () => {
    const { keycloak ,initialized } = useKeycloak();

    if (!initialized) {
        return (
            <div className="auth-container">
                <h2>Loading...</h2>  {/* Display loading state while Keycloak is initializing */}
            </div>
        );
    }
    // Check if Keycloak is ready and the user is authenticated
    console.log("Keycloak is authenticated is " +keycloak.authenticated)
    if (!keycloak) {
        return  (
        <div className="auth-container">
            <h2>Loading...</h2>
        </div> // Loading state if Keycloak isn't ready
        )
    }
    if (!keycloak.authenticated) {
        console.log("NOT AUTHENTICATED")
        // Show login prompt if not authenticated
        return (
            <div className="auth-container">
                      <h2 className="login-message">Please login to access HALLO Data Portal</h2>
                      <p className="photo-credit">Photo by Lucy Quayle</p>
            </div>
        );
    }
    // Check if the user is authenticated
    if (keycloak.authenticated) {
        // Check for roles
        if (keycloak.hasResourceRole('annotator')) {
            return <Navigate to="/annotate" />;
        } else if (keycloak.hasResourceRole('researcher')) {
            return <Navigate to="/main" />;
        }
    }

    return null; // Render nothing if not authenticated or no valid role
};
export default AuthRedirect;