import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, FeatureGroup, LayersControl } from 'react-leaflet';
import "leaflet/dist/leaflet.css";      
import axios from "axios";
import { CircularProgress, Box } from "@mui/material";
import 'leaflet.heat';

import "leaflet-draw/dist/leaflet.draw.css";

import {useKeycloak} from "@react-keycloak/web";
import SightingMarker from "./sighting_marker/SightingMarker";
import AISMarker from "./ais_marker/AISMarker";
import HydrophoneMarker from "./hydrophone_marker/HydrophoneMarker";
import HabitatLayer from "./srkw_habitat_density/DensityHabitatLayer";
import ForecastLayer from "./forecast_layer/ForecastLayer";
import HydrophoneInfoPanel from "./hydrophone_marker/HyrdophonePanel";
import SafeZoneLayer from "./safe_zone/SafeZoneLayer";
import ShipLaneLayer from "./ship_lane/ShipLane";
import CriticalHabitatLayer from "./critical_habitat/CriticalHabitat";
import AISLegend from "./ais_marker/AIS_Legend";

  
  // Main MapComponent
  const MapComponent = () => {
    const { keycloak } = useKeycloak();
    const [loading, setLoading] = useState(false); 
    const [showSightings, setShowSightings] = useState(false);  // track sighting markers visibility 
    const [showHydrophones, setShowHydrophones] = useState(false);  // hydrophones data (location, organization)
    const [showAISMarker, setShowAISMarker] = useState(false); // Track AIS marker visibility
    const [showHabitatLayer, setShowHabitatLayer] = useState(false);
    const [showForecastLayer, setShowForecastLayer] = useState(false);
    const [showShipLaneLayer, setShowShipLaneLayer]=useState(false); //track ship lane visibility
//####################################################### 


    if (loading) {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
          <Box ml={2}>Loading map data...</Box>
        </Box>
      );
    }


    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>

          {/* Map Section */}
          <div
            style={{
              flex: "0 0 100%", // 
              position: "relative",
            }}
          >
            <MapContainer
              style={{
                height: "90%", 
                width: "100%", 
              }}
              center={[48.5, -123.5]}
              zoom={9}
              maxZoom={13}
              minZoom={6}
            >
              <TileLayer
                url="https://services.arcgisonline.com/ArcGIS/rest/services/Ocean/World_Ocean_Base/MapServer/tile/{z}/{y}/{x}"
              />
          <LayersControl position="topright"  collapsed={false}>
  
              <LayersControl.Overlay name="Sightings">
                <FeatureGroup eventHandlers={{ add: () => setShowSightings(true), remove: () => setShowSightings(false) }}>
                      
                       {showSightings &&     <SightingMarker/> }     
                  
                </FeatureGroup>
              </LayersControl.Overlay>

              {/* ais markers */}
              <LayersControl.Overlay name="AIS Ship Tracker">
              {/* add: Triggered when the group is added to the map. remove:  Triggered when the group is removed from the map */}
                <FeatureGroup eventHandlers={{ add: () => setShowAISMarker(true), remove: () => setShowAISMarker(false) }}>
                
                   {showAISMarker&& <AISMarker/>}
                  
                </FeatureGroup>
              </LayersControl.Overlay>

              {/* hydrophone markers */}
              <LayersControl.Overlay name="Hydrophones">
              <FeatureGroup eventHandlers={{ add: () => setShowHydrophones(true), remove: () => setShowHydrophones(false) }}>

                {showHydrophones &&   <HydrophoneMarker /> }

                </FeatureGroup>
            </LayersControl.Overlay>

              <LayersControl.Overlay name="Historical Density (Habitat)">
                <FeatureGroup>
                  <HabitatLayer />
                </FeatureGroup>
              </LayersControl.Overlay>


              <LayersControl.Overlay name="Interim Sanctuary Zone">
              <FeatureGroup>
                <SafeZoneLayer />
              </FeatureGroup>
            </LayersControl.Overlay>

            <LayersControl.Overlay name="Canadian Critical Habitat">
              <FeatureGroup>
                <CriticalHabitatLayer />
              </FeatureGroup>
            </LayersControl.Overlay>

            <LayersControl.Overlay name="Shipping Lanes">
              <FeatureGroup eventHandlers={{ add: () => setShowShipLaneLayer(true), remove: () => setShowShipLaneLayer(false) }}>
                { ShipLaneLayer && <ShipLaneLayer />}
              </FeatureGroup>
            </LayersControl.Overlay>

            <LayersControl.Overlay name="Movement Forecast" >
                <FeatureGroup eventHandlers={{ add: () => setShowForecastLayer(true), remove: () => setShowForecastLayer(false) }}>
                 { showForecastLayer && <ForecastLayer />}
                </FeatureGroup>
              </LayersControl.Overlay>

            </LayersControl>

              {/* add legend to the map */}
        
             <AISLegend show={showAISMarker}/>
      
           
          </MapContainer>
          </div>

        </div>
      );
      
      
  };
  
  export default MapComponent;