import React from "react";
import { Drawer, Typography, IconButton, Box, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const HydrophoneInfoPanel = ({ hydrophone, onClose }) => {
    if (!hydrophone) return null;

    return (
        <Drawer
            anchor="top"  
            open={Boolean(hydrophone)}
            onClose={onClose}
            sx={{
                '& .MuiDrawer-paper': {
                    width: '90%',  
                    height: '70%',
                    maxWidth: '1200px', // Maximum width for large screens
                     maxHeight: '80vh',
                    left: '50%',  //left edge of drawer is 50% but if we add the drswer width, it wont be centered. So we need the next property
                    transform: 'translateX(-50%)', // Move the drawer back by 50% of its width to center it
                    marginTop: '10%', 
                }
            }}
        >
            <Box sx={{ p: 2 }}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">Hydrophone Details</Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Divider sx={{ my: 2 }} />

                <Typography><strong>Organization:</strong> {hydrophone.name}</Typography>
                <Typography><strong>Latitude:</strong> {hydrophone.latitude}</Typography>
                <Typography><strong>Longitude:</strong> {hydrophone.longitude}</Typography>

                {/* Placeholder for future data */}
                <Box sx={{ mt: 3, height: 200, bgcolor: "#f0f0f0", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography>Graph / Data Placeholder</Typography>
                </Box>
            </Box>
        </Drawer>
    );
};

export default HydrophoneInfoPanel;
