import { useEffect, useState } from "react";
import { FeatureGroup, useMap, useMapEvent } from "react-leaflet";
import georaster from "georaster";
import GeoRasterLayer from "georaster-layer-for-leaflet";
import L from "leaflet";
import chroma from "chroma-js";

const HabitatLayer = () => {
  const map = useMap();
  const [habitatLayer, setHabitatLayer] = useState(null);

  useEffect(() => {
    const loadTiff = async () => {
        try {
          //TO DO: for multiple Habitat Layers from different months
            // const tiffFiles = [
            //     "may.tif", "jun.tif", "jul.tif", "aug.tif", "sep.tif", "oct.tif"
            // ];
            // const colorScales = {
            //     "may.tif": chroma.scale(["#b2ff66", "#006400"]).domain([0, 100]),  
            //     "jun.tif": chroma.scale(["#ffcc00", "#cc0000"]).domain([0, 100]),  
            //     "jul.tif": chroma.scale(["#00bfff", "#00008b"]).domain([0, 100]),  
            //     "aug.tif": chroma.scale(["#ff9999", "#990000"]).domain([0, 100]),  
            //     "sep.tif": chroma.scale(["#f4a300", "#732700"]).domain([0, 100]),  
            //     "oct.tif": chroma.scale(["#32cd32", "#228b22"]).domain([0, 100]),  
            // };


      
      
         const response = await fetch("/static/skrw_habitat/may.tif");
       

          if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
  
          const arrayBuffer = await response.arrayBuffer();
        
  
          const raster = await georaster(arrayBuffer);
  
  
          // Raster min and max values 
          const min = raster.mins[0];
          const max = raster.maxs[0];
          // console.log(`Raster Min: ${min}, Max: ${max}`);
  
          // Define a color scale
      const colorScale = chroma.scale(["#d4f8b7", "#006400"]).domain([min, max]);
            
        // const colorScale = colorScales[tiffFile]).domain([raster.mins[0], raster.maxs[0]]); 

          //convert pixel values to colors
          const pixelValuesToColorFn = (values) => {
            const value = values[0];
            return value === raster.noDataValue ? null : colorScale(value).hex();
          };
  
          const layer = new GeoRasterLayer({
            georaster: raster,
            opacity: 0.75,
            resolution: 64,
            pixelValuesToColorFn,
            // debugLevel: 1,
          });
   
         setHabitatLayer(layer);
        } catch (error) {
          console.error("Error loading TIFF:", error);
        }
        
      };
      

    loadTiff();

  }, []);

  //Handling adding and removing layer based on Mapevents inherited from the Map container
  useMapEvent("overlayadd", (e) => {
    if (e.name === "Historical Density (Habitat)" && habitatLayer) {
      map.addLayer(habitatLayer);
    }
  });

  useMapEvent("overlayremove", (e) => {
    if (e.name === "Historical Density (Habitat)" && habitatLayer) {
      map.removeLayer(habitatLayer);
    }
  });
  return null;
};

export default HabitatLayer;
