import React from 'react';
import {BrowserRouter, BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import {ReactKeycloakProvider, useKeycloak} from '@react-keycloak/web';
import keycloak from "./util/KeyCloakSetting";
import PrivateRoute from './util/PrivateRoute';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppNavbar from "./component/navbar/index";
import Annotator from "./component/annotator";
import MainResearcher from "./component/researcher-main-page/MainPage";
import AuthRedirect from "./util/AuthRedirect";
// import {MapComponent} from './component/map_component/MapComponent';
import MapComponent from './component/map_component/Map';


const eventLogger = (eventType, error) => {

  console.log('onKeycloakEvent', eventType, error);
};



const tokenLogger = (tokens) => {
  console.log('onKeycloakTokens', tokens);
};



function App() {
console.log("This is env "+process.env.REACT_APP_KeyCloakServerUrl);
  return (
      <ReactKeycloakProvider
          authClient={keycloak}
          onEvent={eventLogger}
          onTokens={tokenLogger}>
        <BrowserRouter>
          <AppNavbar />
            {/*<AuthRedirect /> /!* Handle redirection here *!/*/}

            <Routes>
            {/*<Route path="/" element={<h2 style={{ textAlign: "center", margin:"10%"}}>Please login to access the app</h2>} />*/}
                <Route path="/" element={<AuthRedirect />} />



                <Route path="/main" element={
                  <PrivateRoute role="researcher">
                      <MainResearcher />
                  </PrivateRoute>
              } />

                <Route path="/annotate" element={
                    <PrivateRoute role="annotator">
                        <Annotator />
                    </PrivateRoute>
                } />
                <Route path="/map" element={
                    <PrivateRoute role="researcher">
                      <div> <MapComponent/></div>
                       
                    </PrivateRoute>
                } />

            </Routes>
        </BrowserRouter>
      </ReactKeycloakProvider>
  );
}

export default App;
