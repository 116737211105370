import React, { useEffect, useState } from "react";
import { Polygon, FeatureGroup, Polyline } from "react-leaflet";
import * as d3 from "d3";
import { CircularProgress } from "@mui/material"; // Material UI Spinner

const CriticalHabitatLayer = () => {
  const [lanes, setLanes] = useState([]); // Store ship lanes
  const [loading, setLoading] = useState(true); // loading state

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Show loading spinner

        const response = await fetch("/static/critical_habitat/habitat.csv");
        const text = await response.text();
        const parsedData = d3.csvParseRows(text);
        const laneList = [];
        let currentLane = [];
        let index=0;
        // skip the first row
        parsedData.slice(1).forEach((row) => {
          const [rowNum, lng, lat] = row;

          // If lat or lng is NA, the polygone is complete
          if (lat === "NA" || lng === "NA") {
            index++;
            if (currentLane.length > 0) {
              laneList.push(currentLane); // Add current lane to the list of lanes
              currentLane = []; // Reset the current lane for the next set of points
            }
          } else {

            // Add valid lat/lng values to the current lane
            if (!isNaN(parseFloat(lat)) && !isNaN(parseFloat(lng))) {
              currentLane.push([parseFloat(lat), parseFloat(lng)]);
            }
          }
        });

        console.log("total number of lanes "+ index)

        // Push the last lane if not empty
        if (currentLane.length > 0) {
          laneList.push(currentLane);
        }

        setLanes(laneList); // Update the lanes state when all lanes are read
        setLoading(false); // Hide loading spinner
      } catch (error) {
        console.error("Error loading ship lanes:", error);
        setLoading(false); // Hide spinner if there was an error
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {loading ? (
        // Show the spinner while loading
        <CircularProgress />
      ) : (
        
        <FeatureGroup>
          {lanes.map((lane, index) => (
            <Polyline
              key={index}
              positions={lane}
              color="blue" 
              weight={2}
              opacity={0.6}
            />
          ))}
        </FeatureGroup>
      )}
    </div>
  );
};

export default CriticalHabitatLayer;
