import React, { useEffect, useState } from 'react';
import { Marker, Popup } from 'react-leaflet';
import { useKeycloak } from '@react-keycloak/web';
import axios from 'axios';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Whale and Orca icons
const whaleIcon = L.divIcon({
    html: "<div style='font-size: 40px;'>🐋</div>",
    className: 'custom-marker',
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    popupAnchor: [0, -35],
});

const orcaIcon = L.divIcon({
    html: "<div style='font-size: 40px;'>🐬</div>",
    className: 'custom-marker',
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    popupAnchor: [0, -35],
});

const SightingMarker = () => {
    const [sightings, setSightings] = useState([]);
    const { keycloak } = useKeycloak();

    useEffect(() => {
        const fetchSightingData = async () => {
            try {
                const response = await axios.get('/api/data/sightings', {
                    headers: { Authorization: `Bearer ${keycloak.token}` },
                });
                setSightings(response.data || []);
            } catch (error) {
                console.error('Error fetching Sighting data:', error);
            }
        };

        fetchSightingData();
        const interval = setInterval(fetchSightingData, 180000); // Refresh every 3 minutes
        return () => clearInterval(interval);
    }, [keycloak.token]);

    return (
        <>
            {sightings.map(sighting => (
                <Marker
                    key={sighting._id}
                    position={[sighting.latitude, sighting.longitude]}
                    icon={sighting.type.includes('Southern Resident Orca') ? orcaIcon : whaleIcon}
                >
                    <Popup>
                        Type: {sighting.type} <br />
                        Time: {sighting.created}
                    </Popup>
                </Marker>
            ))}
        </>
    );
};

export default SightingMarker;
