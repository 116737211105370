import { useEffect, useState } from "react";
import { Polygon, FeatureGroup, Popup } from "react-leaflet";

//d3 is used to handle documents including csv
import * as d3 from "d3";

// A fixed numbre of colours for safe zones
const palette = [
    "#ff6347", // Red
    "#32cd32", // Green
    "#1e90ff", // Blue
    "#8a2be2", // Violet
    "#ff8c00", // Dark Orange
  ];

const SafeZoneLayer = () => {
  const [zones, setZones] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("We are reading the safe zone csv")
        const response = await fetch("/static/safe_zone/isz.csv");
        const text = await response.text();

        const parsedData = d3.csvParseRows(text);
        const zoneMap = {};
        let currentZone = null;
        let colorIndex = 0;
        parsedData.forEach((row) => {
          const [zone, lat, lng] = row;
          if (zone === "ISZ") return; // skip the first row

         // Reset zone on "Break"
          if (zone === "Break") {
            currentZone = null;
            return;
          }
          //check the status of the lat and long if there are in expected type
          if (!isNaN(parseFloat(lat)) && !isNaN(parseFloat(lng))) {
            if (!currentZone) currentZone = zone;


            if (!zoneMap[currentZone]) {
              zoneMap[currentZone] = {
                name: currentZone,
                coordinates: [],
                color: palette[colorIndex % palette.length], // select a colour from the palette
              };

              colorIndex++;
            }

            zoneMap[currentZone].coordinates.push([parseFloat(lat), parseFloat(lng)]);
          }
         
        });
        //convert to an array of objects from  object of objects

        setZones(Object.values(zoneMap));

      } catch (error) {
        console.error("Error loading Zones:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <FeatureGroup>
      {zones.map((zone, index) => (
           <Polygon
           key={index}
           positions={zone.coordinates}
           color={zone.color}
         >
           <Popup>{zone.name} zone</Popup> 
         </Polygon>
      ))}
    </FeatureGroup>
  );
};

export default SafeZoneLayer;
