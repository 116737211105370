import React, { useState, useEffect } from "react";
import "./SliderControl.css";

const SliderControl = ({ min, max, value, onChange , timestamps}) => {
    const [isPlaying, setIsPlaying] = useState(false);
    
  
    useEffect(() => {
      let interval;
      if (isPlaying) {
        interval = setInterval(() => {
          onChange({ target: { value: value < max ? value + 1 : min } });
        }, 1000);
      }
      return () => clearInterval(interval);
    }, [isPlaying, value, min, max, onChange]);
  
    const togglePlay = () => {
      setIsPlaying(!isPlaying);
    };
  
    const handleSliderChange = (event) => {
      if (isPlaying) {
        setIsPlaying(false); 
      }
      onChange(event);
    };

  return (
    <div className="slider-wrapper">
    {/* Color Gradient Bar Outside the Slider Container */}
    <div className="color-gradient-container">
  <div className="gradient-labels">
    <span className="gradient-label-left">0%</span>
    <span className="gradient-label-right">100%</span>
  </div>
  <div className="color-gradient-bar"></div>
    </div>

    {/* Sliding bar container  */}
    <div className="slider-container">

      <div className="notice">Forecast Model</div>
      <button className="play-button" onClick={togglePlay}>
        {isPlaying ? "⏸" : "▶"}
      </button>
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        step="1"
        onChange={onChange}
        className="slider"
      />
            {timestamps.length > 0 && (
        <div className="band-label">
          Time: {timestamps[value]} 
        </div>
      )}

    </div>
    </div>
  );
  
};

export default SliderControl;

