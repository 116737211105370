import React, { useEffect, useState } from 'react';
import { Marker, Popup } from "react-leaflet";
import {useKeycloak} from "@react-keycloak/web";
import axios from "axios";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import AISLegend from './AIS_Legend';

const categoryColors = {
    passenger: "blue",
    cargo: "green",
    fishing: "red",
    towing: "orange",
    pleasure: "yellow",
    high_speed: "purple",
    other: "gray"
};

const normalizeCategory = (category) => {
    const categoryMap = { "towing/tug": "towing" };
    return categoryMap[category] || category;
};
const theme = createTheme(); // Create a default theme or customize it


const shipIcon = (category, course) => {
    const arrowIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
        <g fill="none" stroke="${categoryColors[category] || 'gray'}" stroke-width="2">
            <path d="M12 2L17 12L12 22L7 12Z" fill="${categoryColors[category] || 'gray'}" stroke="none"/>
            <rect x="9" y="16" width="6" height="3" fill="${categoryColors[category] || 'gray'}"/>
        </g>
    </svg>`;
    return new L.divIcon({
        className: "custom-ship-icon",
        html: `<div style="transform: rotate(${course}deg);">${arrowIcon}</div>`,
        iconSize: [30, 30],
        iconAnchor: [15, 15],
        popupAnchor: [0, -15]
    });
};

const anchorIcon = (category) => {
    return new L.divIcon({
        className: "custom-ship-icon",
        html: `<div style="width: 8px; height: 8px; background-color: ${categoryColors[category] || 'gray'}; border-radius: 50%;"></div>`,
        iconSize: [10, 10],
        iconAnchor: [5, 5],
        popupAnchor: [0, -15]
    });
};

const AISMarker = () => {
    const [ships, setShips] = useState([]);
    const { keycloak } = useKeycloak();
    // Authorization header
    const headers = {
      Authorization: `Bearer ${keycloak.token}`,
    };

    useEffect(() => {
      
        const fetchAISData = async () => {
            try {
                const response = await axios.get("api/data/ais/ships/all", { headers }); 
                setShips(response.data || []); // access response.data
            } catch (error) {
                console.error("Error fetching AIS data:", error);
            }
        };
        
        fetchAISData();
         const interval = setInterval(fetchAISData, 60000); // Refresh every 1 minutes
          return () => clearInterval(interval);
    }, []); 

    return (
        <>
            {ships.map(ship => {
                if (!ship.LATITUDE || !ship.LONGITUDE) return null;

                const course = ship.COG || 0;
                const movementStatus = ship.MOVEMENT_STATUS || "Unknown";
                const category = normalizeCategory(ship.CATEGORY || 'other');
                const markerIcon = movementStatus === "moving" ? shipIcon(category, course) : anchorIcon(category);

                return (
                    <Marker key={ship.MMSI} position={[ship.LATITUDE, ship.LONGITUDE]} icon={markerIcon}>
                        <Popup>
                            <b>{ship.NAME}</b> <br />
                            MMSI: {ship.MMSI} <br />
                            Time: {new Date(ship.TIME).toLocaleString()} <br/>
                            Category: {ship.CATEGORY}<br/>
                            Course: {course}° <br/>
                            Movement: {movementStatus}
                        </Popup>
                    </Marker>
                );
            })}

 
        </>
    );
};

export default AISMarker;
