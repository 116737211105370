/* This function returns an array of timestamps starting 
from the file name time stmap to number of bands in the fiel multiply 3 minutes*/

export const extractTimestamp = (fileName, n) => {
    // regex to match "YYYY-MM-DDTHH-MM-SS" or "YYYY-MM-DD HH:MM:SS"
    const regex = /(\d{4}-\d{2}-\d{2}[T ]\d{2}[-:]\d{2}[-:]\d{2})/;
    const match = fileName.match(regex);
    
    if (!match) {
        throw new Error('Invalid file name format');
    }

    // Convert HH-MM-SS to HH:mm:ss
    let startTimestamp = match[1].replace(/-(\d{2})-(\d{2})$/, ':$1:$2');
    startTimestamp = startTimestamp.replace('T', ' '); 

    // Date object in UTC
    const startDate = new Date(startTimestamp + 'Z'); 
    
    if (isNaN(startDate.getTime())) {
        throw new Error('Invalid date format');
    }

    const timestamps = [];

    // Generate n timestamps, each 3 minutes apart based on the file name and number of bands in the file
    for (let i = 0; i < n; i++) {
        const newTimestamp = new Date(startDate.getTime() + i * 3 * 60 * 1000);
        
        // Format the timestamp as "YYYY-MM-DD HH:MM:SS"
        const formattedTimestamp = newTimestamp.toISOString()
            .replace('T', ' ') 
            .slice(0, 19); // Take only YYYY-MM-DD HH:MM:SS

        timestamps.push(formattedTimestamp);
    }

    return timestamps; 

};
