import { useEffect, useState, useRef } from "react";
import { useMap } from "react-leaflet";
import georaster from "georaster";
import GeoRasterLayer from "georaster-layer-for-leaflet";
import axios from "axios";
import chroma from "chroma-js";
import "./Forecast.css";
import SliderControl from "./Slidingbar";
import { useKeycloak } from "@react-keycloak/web";
import { extractTimestamp } from "../../../util/Misc";

const ForecastLayer = () => {
  const map = useMap();

  // Using useRef to prevent re-rendering when the layer is updated
  const forecastLayerRef = useRef(null);
  const { keycloak } = useKeycloak();
  const [raster, setRaster] = useState(null);
  const [currentBand, setCurrentBand] = useState(0);  // Starting from the first band
  const [bandCount, setBandCount] = useState(0);
  const [timestamps, setTimestamps] = useState([]);
  const [loading, setLoading] = useState(true); // Track loading state

  // Function to load the TIFF file
  const loadTiff = async () => {
    try {
      setLoading(true);

      const token = keycloak.token;
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get("api/data/file/forecastTiff", {
        headers,
        responseType: "arraybuffer",
      });

      const arrayBuffer = await response.data;
      const rasterData = await georaster(arrayBuffer);

      const contentDisposition = response.headers['content-disposition'];
      const name=contentDisposition ? contentDisposition.split('filename=')[1] : 'KDE_2021-09-18 10-40-00.tif';

      setRaster(rasterData);
      setBandCount(rasterData.values.length);

      const generatedTimestamps = extractTimestamp(name, rasterData.values.length);
      setTimestamps(generatedTimestamps);

      // Set to the first band after loading
      setCurrentBand(0); // Reset to the first band
      updateLayer(rasterData, 0); // Update with the first band
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error loading TIFF:", error);
    }
  };

  // Update the layer on the map
  const updateLayer = (rasterData, bandIndex) => {
    if (forecastLayerRef.current) {
      map.removeLayer(forecastLayerRef.current);
    }

    const min = rasterData.mins[bandIndex];
    const max = rasterData.maxs[bandIndex];

    // Color scale based on the data values
    const colorScale = chroma.scale(["#ffcccc", "#990000"]).domain([min, max]);

    const pixelValuesToColorFn = (values) => {
      const value = values[bandIndex];
      if (value === rasterData.noDataValue || value === null || isNaN(value)) {
        return null;
      }
      return colorScale(value).hex();
    };

    const layer = new GeoRasterLayer({
      georaster: rasterData,
      opacity: 0.75,
      resolution: 64,
      pixelValuesToColorFn,
    });

    layer.addTo(map);
    forecastLayerRef.current = layer;
  };

  // Handle slider change
  const handleSliderChange = (e) => {
    const bandIndex = parseInt(e.target.value, 10);
    setCurrentBand(bandIndex);
    if (raster) {
      updateLayer(raster, bandIndex);
    }
  };

  //  interval to refresh the forecast data every 5 minutes
  useEffect(() => {
    loadTiff(); // Initial load of the TIFF file

    const interval = setInterval(() => {
      loadTiff(); // Refresh the TIFF file every 5 minutes (300000 ms)
    }, 300000); 

    // Cleanup the interval when the component unmounts
    return () => {
      clearInterval(interval);
      if (forecastLayerRef.current) {
        map.removeLayer(forecastLayerRef.current);
      }
    };
  }, [keycloak.token]); // dependency based on  effect when the component mounts or token changes

  return (
    <div className="forecast-layer-controls">
      {loading && (
        <div className="spinner">
          <div className="loader"></div>
          <div className="loading-text">Loading/Updating Movement Forecast...</div>
        </div>
      )}

      {!loading && raster && (
        <SliderControl
          min={0}
          max={bandCount - 1}
          value={currentBand} // set to the current band
          timestamps={timestamps}
          onChange={handleSliderChange}
        />
      )}
    </div>
  );
};

export default ForecastLayer;
