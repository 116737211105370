import React, {useEffect} from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { useKeycloak } from '@react-keycloak/web';
import {useNavigate} from "react-router-dom";

function AppNavbar() {
    const { keycloak } = useKeycloak();
    const navigate =useNavigate();
    const handleLogin = () => {
        keycloak.login();
    };
    useEffect(() => {
        // Function to handle logout
        const handleLogout = () => {
            console.log("User has logged out.");
            // Here you can perform additional actions, like redirecting the user or clearing state
           // window.location.href = '/'; // Redirect to the home page or login page
            navigate("/");
        };

        // Check if Keycloak is ready
        if (keycloak) {
            // Listen for logout event
            keycloak.onAuthLogout = handleLogout;
        }

        // Cleanup function to avoid memory leaks
        return () => {
            if (keycloak) {
                keycloak.onAuthLogout = null; // Remove the event listener when the component unmounts
            }
        };
    }, [keycloak]);

    // const handleLogout = () => {
    //     keycloak.logout();
    //     navigate("/");
    // };
    const handleLogout = () => {
        // keycloak.logout().then(() => {
        //     navigate("/");
        // }).catch(error => {
        //     console.error("Logout failed:", error);
        // });
        keycloak.logout({ redirectUri: window.location.origin + "/" });
    };
    return (
        <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
              <Navbar.Brand    onClick={() => {navigate("/");}} className="px-4" style={{ cursor: "pointer" }}>
                HALLO Data Portal
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto"></Nav>

                <Nav className="ml-auto px-4">
                    {keycloak?.authenticated ? (
                        <Button variant="outline-light" onClick={handleLogout}>
                            Logout
                        </Button>
                    ) : (
                        <Button variant="outline-light" onClick={handleLogin}>
                            Login
                        </Button>
                    )}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default AppNavbar;
