import {useKeycloak} from "@react-keycloak/web";
import React from "react";
import { Navigate } from "react-router-dom";
const PrivateRoute=({children,role})=>{
    const {keycloak} =useKeycloak();
  
    if(!keycloak.authenticated){
        return <Navigate to="/" />;
    }
    const hasResourceRole = keycloak.tokenParsed?.resource_access?.[keycloak.clientId]?.roles?.includes(role);

    return hasResourceRole ? children : <Navigate to="/"/>


};
export default PrivateRoute;

