import React, { useEffect, useState } from 'react';
import { Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { useKeycloak } from '@react-keycloak/web';
import axios from 'axios';
import 'leaflet/dist/leaflet.css';
import HydrophoneInfoPanel from './HyrdophonePanel';

// Custom icon for hydrophone marker
const hydrophoneIcon = L.divIcon({
    html: "<div style='font-size: 32px;'>🎙️</div>",
    className: "custom-marker",
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -28]
});

const HydrophoneMarker = ({ onClick }) => {
    const [hydrophones, setHydrophones] = useState([]);
    const [selectedHydrophone, setSelectedHydrophone] = useState(null); // store the selected hydrophone

    const { keycloak } = useKeycloak();

    // Authorization header for requests
    const headers = {
        Authorization: `Bearer ${keycloak.token}`,
    };

    useEffect(() => {
        const fetchHydrophones = async () => {
            try {
                const response = await axios.get('/api/data/hydrophone/all', { headers });
                setHydrophones(response.data || []);
            } catch (error) {
                console.error('Error fetching hydrophones:', error);
            }
        };
        fetchHydrophones();
    }, [keycloak.token]);

    const handleClick = (hp) => {
        setSelectedHydrophone(hp); // Set the selected hydrophone
    };

    return (
        <>
            {hydrophones.filter(hp => ["SIMRES", "SMRU", "ONC"].includes(hp.name))
            .map((hp, index) => (
                <Marker
                    key={index}
                    position={[hp.latitude, hp.longitude]}
                    icon={hydrophoneIcon}
                    eventHandlers={{
                        click: () => handleClick(hp), // set the selected hydrophone
                    }}
                />
            ))}

            {/* Display HydrophoneInfoPanel if a hydrophone is selected */}
            {selectedHydrophone && (
                <HydrophoneInfoPanel 
                    hydrophone={selectedHydrophone} 
                    onClose={() => setSelectedHydrophone(null)} // Close the info panel
                />
            )}
        </>
    );
};

export default HydrophoneMarker;
