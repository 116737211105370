import React from 'react';
import { Box, Typography } from '@mui/material';

const categoryColors = {
  passenger: "blue",
  cargo: "green",
  fishing: "red",
  towing: "orange",
  pleasure: "yellow",
  high_speed: "purple",
  other: "gray"
};

const movementStatusColors = {
  moving: "black", // For the moving ship icon
  notMoving: "black"  // For the not moving black circle
};

const ShipIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
    <g fill="none" stroke={color} strokeWidth="1.5">
      <path d="M12 2L17 12L12 22L7 12Z" fill={color} stroke="none" />
      <rect x="9" y="16" width="6" height="2" fill={color} />
    </g>
  </svg>
);

const AISLegend = ({ show }) => {
  if (!show) return null;// Hide the legend when 'show' is false

  return (
    <Box
      sx={{
        position: "absolute",
        bottom: "3%",
        right: 20,
        backgroundColor: "white",
        padding: "8px",
        borderRadius: "4px",
        boxShadow: 1,
        minWidth: "80px",
        zIndex: 1000,
        fontSize: "0.75rem", 
        lineHeight: "1.2",    
      }}
    >
      {/* Vessel Types Legend */}
      <Box sx={{ marginBottom: 1, borderBottom: "1px solid #ddd", paddingBottom: 1 }}>
        <Typography sx={{ fontWeight: 'bold', fontSize: '0.75rem', marginBottom: '6px' }}>Vessel Types</Typography>
        {Object.keys(categoryColors).map((category) => (
          <Box
            key={category}
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "4px"
            }}
          >
            <div
              style={{
                width: "10px",      
                height: "10px",
                borderRadius: "2%",
                marginRight: "6px",
                backgroundColor: categoryColors[category]
              }}
            />
            <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>
              {category.charAt(0).toUpperCase() + category.slice(1)}
            </Typography>
          </Box>
        ))}
      </Box>

      {/* Movement Status Legend */}
      <Box>
        <Typography sx={{ fontWeight: 'bold', fontSize: '0.75rem', marginBottom: '6px' }}>Movement Status</Typography>
        
        <Box display="flex" alignItems="center" mb={0.5}>
          <ShipIcon color="black" />
          <Typography sx={{ fontSize: '0.7rem', marginLeft: '4px' }}>Moving Ships</Typography>
        </Box>
        
        <Box display="flex" alignItems="center">
          <div
            style={{
              width: "10px",     
              height: "10px",
              borderRadius: "50%",
              marginRight: "4px",
              marginLeft: "3px",
              backgroundColor: movementStatusColors.notMoving
            }}
          />
          <Typography sx={{ fontSize: '0.7rem' }}>Stationery</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AISLegend;
