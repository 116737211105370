import Keycloak from 'keycloak-js';

// Initialize Keycloak instance
const keycloak = new Keycloak({
    
    url: process.env.REACT_APP_KeyCloakServerUrl,// Keycloak base URL
    realm: 'hallo-system', // realm name
    clientId: 'data-portal', // client ID
});

export default keycloak;
